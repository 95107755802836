import React from "react"
import { Navbar } from "react-bootstrap"

export default class Header extends React.Component {
  render() {
    return (
      <>
        <Navbar className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav" expand="lg"
                collapseOnSelect={true}>
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="/">
              <img src={'/banner.png'} alt="Logo" style={{ height: `3em` }}  href="#page-top" />
            </a>
          </div>
        </Navbar>
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-10 align-self-end">
                <h1 className="text-uppercase text-black font-weight-bold">
                  <img src={'/bokuami-logo.png'} style={{ width: `90%`}} alt="ボクラノアミモノ ロゴ" />
                </h1>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
