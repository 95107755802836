import React from "react"

import Layout from "../components/simple-layout"

export default () => (
  <Layout>
    <section className="page-section" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-left">
  <p>ボクラノアミモノ事務局（以下「事務局」といいます）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全構成員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。</p>

<h4>個人情報の管理</h4>
<p>事務局は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・構成員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。</p>

<h4>個人情報の利用目的</h4>
<p>お客さまからお預かりした個人情報は、事務局からのご連絡や活動に関するご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。</p>
<h4>個人情報の第三者への開示・提供の禁止</h4>
<p>事務局は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
<ul>
<li>お客さまの同意がある場合</li>
<li>お客さまが希望されるサービスを行なうために事務局が業務を委託する業者に対して開示する場合</li>
<li>法令に基づき開示することが必要である場合</li>
</ul>
<h4>個人情報の安全対策</h4>
<p>事務局は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</p>
<h4>ご本人の照会</h4>
<p>お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。</p>
<h4>法令、規範の遵守と見直し</h4>
<p>事務局は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</p>
<p>
お問い合せ<br/>
事務局の個人情報の取扱に関するお問い合せは下記までご連絡ください。<br/>
  <a className="d-block" href="mailto:bokuranoamimono@gmail.com">bokuranoamimono@gmail.com</a>
  運営会社: Mayds Inc.
</p>

          </div>
        </div>
      </div>
    </section>
  </Layout>
)

